/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CSSTransition from "react-transition-group/CSSTransition";
import DisplayName from "../DisplayName";
export default class EntityListThumbnail extends PureComponent {
  static displayName = "EntityList.Thumbnail";

  static propTypes = {
    entity: PropTypes.object.isRequired,
    path: PropTypes.string,
    type: PropTypes.string,
    showTime: PropTypes.bool,
    showYear: PropTypes.bool,
    venue: PropTypes.string,
    category: PropTypes.string,
    className: PropTypes.string,
  };

  get url() {
    const path = this.props.path;
    const slug = this.props.entity.slug;
    const param = this.props.entity.date_param;

    if (param && path) return `${path}/${slug}?${param}`;
    if (path) return `${path}/${slug}`;

    if (param) return `${slug}?${param}`;

    return slug;
  }

  get sold_out() {
    if (this.props.entity.sold_out)
      return (
        <span className="m-sold-out m-sold-out__event-landing">SOLD OUT</span>
      );
  }

  render() {
    const { slug, name, display_name, imageSmall } = this.props.entity;
    const blockClass = "m-entity-thumbnail";

    return (
      <CSSTransition in={true} timeout={200} classNames="react-appear-" appear>
        <li className={classNames(blockClass, this.props.className)}>
          <a href={this.url} className={`${blockClass}__link`}>
            <article className={`${blockClass}__inner`}>
              <header className={`${blockClass}__heading`}>
                <h2 className={`${blockClass}__title`}>
                  <DisplayName name={name} displayName={display_name} />
                </h2>
                {this.props.category && (
                  <div className={`${blockClass}__category`}>
                    {this.props.category}
                  </div>
                )}
              </header>
              {this.sold_out}
              <div className={`${blockClass}__description`}>
                <div>{this.props.venue}</div>
                <time
                  dangerouslySetInnerHTML={{
                    __html: this.props.entity.date_info,
                  }}
                />
              </div>
              <figure className={`${blockClass}__figure`}>
                <img
                  className={`${blockClass}__image m-lazy-image`}
                  src={imageSmall}
                  alt=""
                  loading="lazy"
                  onLoad={(e) => e.target.classList.add("is-loaded")}
                />
              </figure>
            </article>
          </a>
        </li>
      </CSSTransition>
    );
  }
}
