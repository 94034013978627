// global callback function for JSONP form submission
// attached to window object by custom element instance
function mcSubscribeCallback({ result }) {
  const signupEl = document.querySelector("newsletter-signup");
  signupEl?.setAttribute("data-response", result);
}

export default class NewsletterSignup extends HTMLElement {
  static define() {
    customElements.define("newsletter-signup", this);
  }

  static observedAttributes = ["data-response"];

  constructor() {
    super();
  }

  get form() {
    return this.querySelector("form");
  }

  get output() {
    return this.querySelector("output");
  }

  get emailInput() {
    return this.querySelector("#mce-EMAIL");
  }

  get emailInputError() {
    return this.querySelector("#mce-EMAIL-error");
  }

  get validityMessage() {
    const validity = this.emailInput.validity;

    if (validity.valid) return "";
    if (validity.typeMismatch) return "Please enter a valid e-mail address.";
    if (validity.valueMissing) return "This is a required field.";
    // If all else fails, return a generic error
    return "The value you entered for this field is invalid.";
  }

  connectedCallback() {
    if (!this.form) return;

    this.form.addEventListener("submit", this.handleSubmit);

    if (!window.mcSubscribeCallback) {
      window.mcSubscribeCallback = mcSubscribeCallback;
    }
  }

  disconnectedCallback() {
    if (!this.form) return;

    this.form.removeEventListener("submit", this.handleSubmit);
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === "data-response") {
      if (newValue === "success" || newValue === "error") {
        this.output.className = newValue;
      }

      this.output.innerText =
        newValue === "error" ? "There was an error submitting the form." : "";
    }
  }

  // MC subscribe endpoints don't support CORS, so we have to use JSONP
  handleSubmit = (event) => {
    event.preventDefault();

    this.output.innerText = "";
    this.output.className = "";

    this.emailInputError.innerText = this.validityMessage;

    if (!this.emailInput.validity.valid) {
      return;
    }

    const url = new URL(event.target.action);
    url.searchParams.set("c", "mcSubscribeCallback");

    const formData = new FormData(event.target);
    for (const [key, value] of formData.entries()) {
      if (value) {
        url.searchParams.set(key, value);
      }
    }

    // Create script with url and callback (if specified)
    const script = window.document.createElement("script");
    script.src = url.toString();

    // Insert script tag into the DOM
    const ref = window.document.getElementsByTagName("script")[0];
    ref.parentNode.insertBefore(script, ref);

    // After the script is loaded (and executed), remove it
    script.onload = function () {
      this.remove();
    };
  };
}
